import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Multiple Testing Correction`}</h1>
    <ul>
      <li parentName="ul">{`When exploring relationships between metadata and opinion groups, if statistical significance of relationships discovered is desirable, it is important that `}<a parentName="li" {...{
          "href": "/Multiple-Testing-Correction",
          "title": "Multiple Testing Correction"
        }}>{`Multiple Testing Correction`}</a>{` be taken into account when running multiple statistical tests on a particular set of data`}</li>
      <li parentName="ul">{`Imagine you generated a bunch of random data for 20 variables, and split that data into two groups. If you run a statistical test for each of these variables to see whether the data was differently distributed between the groups, you'd expect (on average) that one out of 20 of those tests would end up producing a "significant" P-value, under the common `}<span parentName="li" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5782em",
                  "verticalAlign": "-0.0391em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.0037em"
                }
              }}>{`α`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`5`}</span></span></span></span>{` significance threshold.`}
        <ul parentName="li">
          <li parentName="ul">{`Congratulations, you've just `}<a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Data_dredging"
            }}>{`P-hacked`}</a>{`!`}
            <ul parentName="li">
              <li parentName="ul">{`PS This is not a good thing...`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`The proper thing to do when you run multiple tests in this manner is to somehow adjust your thresholds for statistical significance so that the `}<strong parentName="li">{`overall`}</strong>{` probably of having a false positive is less than the desired significance threshold, `}<span parentName="li" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.43056em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.0037em"
                }
              }}>{`α`}</span></span></span></span>{`.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Bonferroni_correction"
        }}>{`Bonferonni method`}</a>{` is one such method:`}
        <ul parentName="li">
          <li parentName="ul">{`In the imaginary case above, one would multiple each P-value by the number of statistical tests performed. In this case, it can be proven that the chance of some test coming out positive by change (under the null-hypothesis) is less than the significance threshold.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Holm%E2%80%93Bonferroni_method"
        }}>{`Holm-Bonferroni method`}</a>{` is a slight modification of the Bonferonni method, `}<a parentName="li" {...{
          "href": "((eWVe2e4UB))"
        }}>{`described above`}</a>{`, which is somewhat more powerful:`}
        <ul parentName="li">
          <li parentName="ul">{`With this method, you:`}
            <ul parentName="li">
              <li parentName="ul">{`Sort the P-values in ascending order`}</li>
              <li parentName="ul">{`Multiply the first P-value by the number of tests (`}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.68333em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.10903em"
                        }
                      }}>{`N`}</span></span></span></span>{`), as with the `}<a parentName="li" {...{
                  "href": "((mh0zU0ZOA))"
                }}>{`Bonferoni method`}</a></li>
              <li parentName="ul">{`Multiply the next P-value by `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.76666em",
                          "verticalAlign": "-0.08333em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.10903em"
                        }
                      }}>{`N`}</span><span parentName="span" {...{
                        "className": "mspace",
                        "style": {
                          "marginRight": "0.2222222222222222em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mbin"
                      }}>{`−`}</span><span parentName="span" {...{
                        "className": "mspace",
                        "style": {
                          "marginRight": "0.2222222222222222em"
                        }
                      }}></span></span><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.64444em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord"
                      }}>{`1`}</span></span></span></span></li>
              <li parentName="ul">{`etc...`}</li>
              <li parentName="ul">{`Stop when one of these modified P-values fails to pass the significance threshold, `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.43056em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.0037em"
                        }
                      }}>{`α`}</span></span></span></span></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      